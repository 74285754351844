<template>
	<div class="pui-form">
		<mtipoequipamiento-modals :modelName="modelName"></mtipoequipamiento-modals>
		<!--<pui-form-header
			v-if="modelLoaded"
			:showHeader="!isCreatingElement">
			<mtipoequipamiento-form-header
				:modelPk="modelPk"
			></mtipoequipamiento-form-header>
		</pui-form-header>-->
		<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
			<v-layout wrap class="pui-form-layout">
				<v-flex xs12 md12>
					<pui-field-set :title="getTitleDatos">
						<v-layout wrap>
							<!-- IDTIPOEQUIP -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`idtipoequip-mtipoequipamiento`"
									v-model="model.idtipoequip"
									:label="$t('mtipoequipamiento.idtipoequip')"
									disabled
									toplabel
									maxlength="10"
								></pui-text-field>
							</v-flex>
							<!-- CODTIPEQUIPEDI -->
							<v-flex xs12 md1>
								<pui-text-field
									:id="`codtipequipedi-mtipoequipamiento`"
									v-model="model.codtipequipedi"
									:label="$t('mtipoequipamiento.codtipequipedi')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="3"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- NAME -->
							<v-flex xs12 md4>
								<pui-text-field
									:id="`name-mtipoequipamiento`"
									v-model="model.name"
									:label="$t('mtipoequipamiento.name')"
									:disabled="formDisabled"
									required
									toplabel
									maxlength="100"
								></pui-text-field>
							</v-flex>
						</v-layout>
						<v-layout wrap>
							<!-- FECBAJA -->
							<v-flex xs12 md3>
								<pui-date-field
									:id="`fecbaja-mtipoequipamiento`"
									v-model="model.fecbaja"
									:label="$t('mtipoequipamiento.fecbaja')"
									:disabled="formDisabled"
									toplabel
									time
								></pui-date-field>
							</v-flex>
						</v-layout>
						<!-- MINI AUDIT -->
						<pui-form-mini-audit class="pl-2" :model="model" v-if="!isCreatingElement"></pui-form-mini-audit>
					</pui-field-set>
				</v-flex>
			</v-layout>

			<!-- Page Footer -->
			<pui-form-footer v-if="!isModalDialog">
				<pui-form-footer-btns
					:formDisabled="formDisabled"
					:saveDisabled="saving"
					:saveAndNew="saveAndNew"
					:saveAndUpdate="saveAndUpdate"
					:save="save"
					:back="back"
				></pui-form-footer-btns>
			</pui-form-footer>
		</v-form>
		<pui-form-loading v-else></pui-form-loading>
	</div>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';
import mtipoequipamientoActions from './MtipoequipamientoActions';
import mtipoequipamientoModals from './MtipoequipamientoModals.vue';

export default {
	name: 'mtipoequipamiento-form',
	mixins: [PuiFormMethodsMixin],
	components: {
		'mtipoequipamiento-modals': mtipoequipamientoModals
	},
	data() {
		return {
			modelName: 'mtipoequipamiento',
			actions: mtipoequipamientoActions.formActions
		};
	},
	methods: {
		afterGetData() {
			// Do something after get data from server
		}
	},
	computed: {
		getTitleDatos() {
			return this.$t('form.mtipoequipamiento');
		}
	},
	created() {}
};
</script>
